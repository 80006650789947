import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "customError",
    "customErrorText",
    "firstName",
    "form",
    "isSubmittingSpinner",
    "lastName",
    "submit",
  ];

  static values = {
    submitBehavior: String, // "emit" or "post"
  };

  connect() {
    if (this.submitBehaviorValue === "emit") {
      this.formTarget.addEventListener("submit", this.#emitOnFormSubmit.bind(this));
    }
  }

  showSpinner() {
    this.isSubmittingSpinnerTarget.hidden = false;
  }

  hideSpinner() {
    this.isSubmittingSpinnerTarget.hidden = true;
  }

  /*
   * functions used by other controllers
   */
  firstName() {
    return this.lastNameTarget?.value;
  }

  lastName() {
    return this.lastNameTarget?.value;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }

  showSubmit() {
    this.submitTarget.hidden = false;
  }

  // This is an hacky workaround for the combination of a few things:
  //   - The need to interrupt form submission to perform async operations (e.g. tokenization)
  //   - If you set an "async" function as a form-submit event listener, the browser doesn't "await" the function
  //   - Hotwire wants to listen to normal form submission events, so we need to use `form.requestSubmit()` instead of `form.submit()`
  // Those things combined mean we do the following when we need async form handling:
  //   1. Set up a form-submit handler that stops normal form submission and triggers async handling
  //   2. After async handling succeeds, re-trigger normal form submission (via `requestSubmit`) but with a special variable set to
  //     skip async handling
  //
  // `func` should be an async function that runs true/false for success/failure.
  addAsyncSubmitInterrupt(func) {
    if (this.asyncSubmitHandler) {
      throw new Error("RINSED: Async submit handler already registered. Only one allowed.");
    } else {
      this.asyncSubmitHandler = func;
    }
    this.formTarget.addEventListener("submit", this.runAsyncSubmitInterrupt.bind(this), true);
  }

  async runAsyncSubmitInterrupt(event) {
    if (this.bypassSubmitInterrupt) {
      return;
    }
    event.stopImmediatePropagation();
    event.preventDefault();
    const success = await this.asyncSubmitHandler();
    if (success) {
      this.bypassSubmitInterrupt = true;
      try {
        this.formRequestSubmit();
      } finally {
        this.bypassSubmitInterrupt = false;
      }
    } else {
      this.enableSubmit();
      this.hideSpinner();
    }
  }

  onFormSubmit(func) {
    this.formTarget.addEventListener("submit", func);
  }

  formRequestSubmit() {
    this.formTarget.requestSubmit();
  }

  setCustomError(message) {
    this.customErrorTarget.hidden = !message;
    this.customErrorTextTarget.innerText = message || "";
  }
  /*
   * end functions used by other controllers
   */

  #emitOnFormSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    const { target } = event;

    target.dispatchEvent(
      new CustomEvent("rinsed:payment_update_form_submit", {
        bubbles: true,
        detail: {
          card_connect_token: this.#formValue(target, "payment_method[card_connect_token]"),
          card_connect_expiry: this.#formValue(target, "payment_method[card_connect_expiry]"),
        },
      })
    );
  }

  #formValue(form, fieldName) {
    return form.querySelector(`input[name='${fieldName}']`).value;
  }
}
